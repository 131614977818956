@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "galvjiregular";
  src: url("assets/fonts/galvji-01-webfont.woff2") format("woff2"),
    url("assets/fonts/galvji-01-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "galvjibold";
  src: url("assets/fonts/galvji-bold-02-webfont.woff2") format("woff2"),
    url("assets/fonts/galvji-bold-02-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "galvjibold_oblique";
  src: url("assets/fonts/galvji-boldoblique-04-webfont.woff2") format("woff2"),
    url("assets/fonts/galvji-boldoblique-04-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "galvjioblique";
  src: url("assets/fonts/galvji-oblique-03-webfont.woff2") format("woff2"),
    url("assets/fonts/galvji-oblique-03-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Variables */

:root {
  --bgColor: #7a98ad;
  --whiteColor: #ffffff;
  --commonTextColor: #8b8b8b;
  --btnGray: #8b8b8b;
  --btnGrayHover: #666666;
  --Lightblue: #7c93a5;
  --LightblueHover: #5b6f7e;
  --RedColor: #d70000;
  --RedColorHover: #ab0000;
}

body {
  font-family: "galvjiregular";
  font-size: 16px;
  line-height: inherit !important;
  color: var(--commonTextColor);
}

.text-xl {
  font-size: 17px !important;
}

.btn {
  padding: 11px 36px !important;
  transition: 0.5s;
  height: 52px;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
  font-size: 16px !important;
  font-weight: bold !important;
}

.btn-red {
  background: var(--RedColor) !important;
  background-color: var(--RedColor) !important;
  transition: 0.5s;
  color: #fff;
  cursor: pointer;
  font-size: 16px !important;
  padding: 11px 36px !important;
  font-weight: bold !important;
}

.btn-red:hover {
  transition: 0.5s;
  background: var(--RedColorHover) !important;
  background-color: var(--RedColorHover) !important;
  color: #fff;
}

.btn-lighblue {
  background: var(--Lightblue) !important;
  background-color: var(--Lightblue) !important;
  transition: 0.5s;
  color: #fff;
  cursor: pointer;
  font-size: 16px !important;
  padding: 11px 36px !important;
  font-weight: bold !important;
}

.btn-lighblue:hover {
  transition: 0.5s;
  background: var(--LightblueHover) !important;
  background-color: var(--LightblueHover) !important;
  color: #fff;
}

.btn-lighblue-border {
  background: transparent !important;
  background-color: transparent !important;
  border: 2px solid var(--Lightblue) !important;
  color: var(--Lightblue) !important;
  transition: 0.5s;
  cursor: pointer;
  font-size: 16px !important;
  padding: 11px 36px !important;
  font-weight: bold !important;
}

.btn-lighblue-border:hover {
  border: 2px solid var(--LightblueHover) !important;
  color: var(--LightblueHover) !important;
  transition: 0.5s;
}

.tox .tox-button {
  background: var(--RedColorHover) !important;
  background-color: var(--RedColorHover) !important;
  padding: 11px 36px !important;
  transition: 0.5s;
  height: 52px;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
  border-radius: 0 !important;
  border: unset !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.tox .tox-button--secondary,
.btn-gray {
  background: var(--btnGray) !important;
  background-color: var(--btnGray) !important;
  transition: 0.5s;
  color: #fff;
  cursor: pointer;
  font-size: 16px !important;
  padding: 11px 36px !important;
  font-weight: bold !important;
}

.tox .tox-button--secondary:hover .btn-gray:hover {
  transition: 0.5s;
  background: var(--btnGrayHover) !important;
  background-color: var(--btnGrayHover) !important;
  color: #fff;
}

.btn-gray,
.tox .tox-button--secondary {
  background: #757575 !important;
  background-color: #757575 !important;
  transition: 0.5s !important;
  color: #fff !important;
  cursor: pointer !important;
  border-radius: 0 !important;
  font-size: 16px !important;
  padding: 11px 36px !important;
  font-weight: bold !important;
}

.btn-dropdown {
  font-weight: initial !important;
}

.tox .tox-button--naked {
  background: transparent !important;
}

.tox .tox-button--naked:hover {
  background: transparent !important;
}

.tox .tox-textarea-wrap .tox-textarea {
  height: auto !important;
}

.white-card {
  width: auto;
  background-color: #ffffff;
}

.organisationProfile-min-h {
  min-height: 144px;
}

#send-clarification {
  width: 900px;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
}

#Cyber-Conenct-Contact-Request-Modal {
  width: 900px;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
}

.textarea-class {
  width: auto;
  overflow: auto;
  outline: none;
  border-radius: 0 !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  min-height: 100px;
  /*remove the resize handle on the bottom right*/
}

/* Table-responsive Strat */
.table-responsive {
  width: 100%;
}

/* Table-responsive Strat */

.small-editor-t .tox-tinymce {
  height: 250px !important;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: inherit !important;
}

a,
button {
  cursor: pointer;
}

a:hover {
  color: var(--bgColor);
}

b,
strong {
  font-weight: bolder !important;
  -webkit-font-smoothing: antialiased !important;
}

.swal2-icon.swal2-success {
  border-color: #a5dc8600 !important;
  color: #01a60100 !important;
}

.main-login-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-main-bg {
  background: #fff;
  border-radius: 10px;
}

.shadow-red {
  box-shadow: 0px 0px 40px -4px #5b6f7e4d;
}

.theme-color {
  color: var(--commonTextColor);
  transition: 0.5s;
}

.lighblue-text {
  color: var(--bgColor);
  transition: 0.5s;
}

.lighblue-text:hover {
  color: #587181;
}

.theme-bg-color {
  background-color: var(--commonTextColor);
}

.theme-text-color {
  color: var(--commonTextColor);
  transition: 0.5s;
}

.theme-active-color {
  color: var(--RedColor);
}

.hover-active-color,
.theme-text-color.active,
.theme-text-color:hover {
  color: var(--RedColor);
  transition: 0.5s;
}

.menu-svg,
.menu-svg path .menu-svg polygon {
  transition: 0.5s;
  fill: var(--commonTextColor);
}

.theme-text-color:hover .menu-svg,
.theme-text-color:hover .menu-svg path,
.theme-text-color:hover .menu-svg polygon {
  fill: var(--RedColor);
  transition: 0.5s;
}

.theme-text-color:hover .menu-svg circle,
.theme-text-color.active .menu-svg circle {
  fill: var(--RedColor);
  transition: 0.5s;
}

.theme-text-color.active .menu-svg path,
.theme-text-color.active .menu-svg polygon {
  fill: var(--RedColor);
  transition: 0.5s;
}

.menu-svg:hover path,
.menu-svg:hover polygon {
  fill: var(--RedColor);
  transition: 0.5s;
}

.menu-svg:hover .menu-svg circle,
.menu-svg.active .menu-svg circle {
  fill: var(--RedColor);
  transition: 0.5s;
}

.menu-svg.active .menu-svg path,
.menu-svg.active .menu-svg polygon {
  fill: var(--RedColor);
  transition: 0.5s;
}

.min-h-30 {
  min-height: 30px;
}

.max-w-70 {
  max-width: 70px;
}

.h-19 {
  height: 19px;
}

.z-ind-1 {
  z-index: 1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

/* Custom-Scrollbar Start */
::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--Lightblue);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--LightblueHover);
}

/* Custom-Scrollbar End */
/* Header Start */
.header-txt {
  font-size: 16px;
}

/* Header End */

/* Breadcome sec Start*/
.breadcome-sec {
  background: #f0f0f0;
}

.breadcome-sec .left-title-sec p {
  font-family: "galvjibold";
  font-size: 32px;
}

.breadcome-sec .right-links-sec li a {
  font-family: "galvjibold";
  font-size: 18px;
}

/* Breadcome sec End*/
/* Sidebar- Menu section Start */

.menu-icon-img .menu-svg {
  min-width: 25px;
}

.disabled-btn {
  /* background: #eb7070ea !important; */
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: none;
}

.menu-icon-img p.menu-title {
  width: 85%;
  /* margin-top: -3px; */
  white-space: break-spaces;
  font-size: 16px !important;
  font-family: "galvjibold";
  font-weight: 500 !important;
}

.sub-menu-title {
  white-space: break-spaces;
  font-size: 16px;
}

.custom-make-menu {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.custom-menu-part {
  left: 35px;
}

.dropdown:focus-within .dropdown-menu {
  display: block;
  transform: translate(0) scale(1);
  visibility: visible;
}

.main-sidebar-logo {
  width: 100%;
}

/* Sidebar- Menu section End */

.main-body-section {
  /* padding: 60px 50px; */
  padding: 35px;
  background: #e1e7ee;
}

.finance-body-sec {
  padding: 40px;
}

.title-text-part .title-text {
  color: var(--bgColor);
  font-size: 26px;
}

.resource-details p {
  font-size: 15px;
}

.font-small-text {
  font-size: 13.6px !important;
}

.credit-btn {
  display: flex;
  align-content: flex-end;
}

.credit-btn .btn {
  padding: 10px 20px;
  transition: 0.5s;
}

.dollor-sign {
  position: relative;
}

.dollor-sign::after {
  content: "$";
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 20px;
  border-right: 1px solid var(--commonTextColor);
  height: 99%;
  color: var(--commonTextColor);
  padding: 15px 20px 15px 15px;
}

/* Custom card Start */
.custom-card {
  background: #fff;
  padding: 50px 55px;
}

/* Custom card End */

/* Custom card2 Start */
.custom-card2 {
  background: #fff;
  padding: 50px 30px;
}

/* Custom card2 End */

/* Custom Table start */

.table-bg {
  background: var(--bgColor);
  background-color: var(--bgColor);
}

.custom-tbody tr:nth-child(even) {
  background: #eff1f7;
}

.custom-action-btn {
  background: linear-gradient(0deg, rgba(199, 199, 199, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

/* Custom Table End */

/* Pagination Start */
.pagination-btn {
  min-width: 350px;
}

.pagination-btn:hover {
  border-color: var(--bgColor);
}

.pagination-btn svg path .pagination-btn svg polygon {
  fill: var(--commonTextColor);
  transition: 0.5s;
}

.pagination-btn:hover svg path,
.pagination-btn:hover svg polygon {
  fill: var(--bgColor);
  transition: 0.5s;
}

.footer-bg {
  background: var(--commonTextColor);
}

/* Pagination End */

/* Custom Tool tip Start */
.action-btn {
  position: relative;
}

.tool-tip-txt {
  transition: 0.5s;
}

.action-btn:hover .tool-tip-txt {
  color: var(--bgColor);
  transition: 0.5s;
}

.action-btn:hover .menu-svg,
.action-btn:hover .menu-svg path,
.action-btn:hover .menu-svg polygon {
  fill: var(--bgColor);
}

/* Custom Tool tip End */

/* Add-Edit Section Start */
.date-pickers {
  position: relative;
}

.date-pickers:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 0.8px;
  background: var(--commonTextColor);
  right: 50px;
  top: 0;
}

.date-pickers:focus.date-pickers:after {
  background: var(--bgColor);
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent !important;
}

::-webkit-calendar-picker-indicator {
  background: url(../public/calender.svg) !important;
  background-position: center !important;
  background-size: contain !important;
}

.date-pickers {
  padding-right: 19px !important;
}

/* Add-Edit Section End */

.custom-tbl-td td {
  font-size: 1.125rem;
  line-height: 1.5;
  letter-spacing: -0.01em;
  text-align: left;
}

.css-1s2u09g-control,
.css-1pahdxg-control {
  border-color: #65758b !important;
  border-radius: 0;
  padding: 7px 5px;
  box-shadow: none !important;
  outline: none !important;
  cursor: pointer !important;
}

.css-1s2u09g-control:hover {
  border-color: #65758b;
}

.css-e56m7-control {
  border-color: #64748b !important;
  padding: 7px 1px;
}

.css-1rjh3ff-control {
  border-color: #c00000 !important;
  padding: 7px 1px;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-6j8wv5-Input input,
.css-6j8wv5-Input input:after {
  border-color: none !important;
  outline: none !important;
  --tw-ring-color: none !important;
}

.form-radio {
  color: #000000 !important;
  width: 25px !important;
  height: 25px !important;
  border-color: #000000 !important;
  padding: 3px !important;
}

.form-radio:focus {
  --tw-ring-color: #2563eb00 !important;
}

.form-radio:checked {
  background-color: #fff !important;
  padding: 10px !important;
  background-image: url(../public/circle-chekbox.svg) !important;
  background-repeat: no-repeat !important;
  background-size: 21px 25px !important;
}

.form-radio.disabled-radio {
  background: #e9e9e9;
  cursor: not-allowed;
}

/* Time Picker Start*/
.css-3g92bx-timepicker {
  position: absolute !important;
  z-index: 1 !important;
}

.css-1wtkbk-time-TopBar,
.css-nntsr1-time,
.css-1mhm3t6-meridiem,
.css-1ctvv7l-numbersStyle {
  color: #000000 !important;
}

.css-rt16tf-timeSelected-time,
.css-1igvx5z-timeSelected-time-TopBar {
  color: var(--LightblueHover) !important;
}

.css-uby47j-centerCircle,
.css-14lwqju-intermediateMinuteCircle {
  fill: var(--LightblueHover) !important;
}

.css-200z2t-outerCircle,
.css-uby47j-centerCircle {
  fill: #f7d4d4 !important;
}

.css-yxfhll-line {
  stroke: #f7d4d4 !important;
}

.css-1n3f8ki-meridiem,
.css-dlroum-meridiem {
  background: var(--LightblueHover) !important;
  color: #fff !important;
}

.time-display span {
  text-transform: uppercase;
  font-size: 18px;
}

.btn-time-formate,
.btn-time-formate button,
.btn-time-formate button span {
  height: 23px;
}

/* Time Picker End*/

/* File Uploader Start */
.custom-uploader:hover {
  border-color: var(--bgColor);
  transition: 0.5s;
}

.text-uplod {
  cursor: pointer;
}

.text-uplod,
.uplod-svg {
  transition: 0.5s;
}

.custom-uploader:hover .text-uplod {
  color: var(--bgColor);
  transition: 0.5s;
}

.custom-uploader:hover .uplod-svg {
  fill: var(--bgColor);
  transition: 0.5s;
}

/* File Uploader End */

.img-logo-upload .close-sign {
  top: -10px;
  right: -10px;
  cursor: pointer;
}

/* CK Editor Start */
/* create privilage */
.post-details .ck-content {
  min-height: 200px;
}

/* CK Editor End */
.font-bold {
  font-family: "galvjibold";
  font-weight: bold !important;
}

.font-bold-500 {
  font-family: "galvjibold";
  font-weight: 500 !important;
}

.table-title-txt {
  font-size: 33px;
  padding-left: 20px;
}

.tbl-th {
  padding: 17px 0;
}

.tbl-txt {
  padding: 10px 0;
}

.tble-th-txt {
  font-size: 22px;
}

.tble-td-txt {
  font-size: 20px;
  color: #231f20;
  padding: 17px 0;
}

.filter-lable,
.font-22 {
  font-size: 19px !important;
}

input,
.btn-dropdown,
select,
textarea {
  height: 57px;
  color: var(--commonTextColor);
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.login-input {
  height: auto;
}

#add-edit-modal .header-title {
  font-size: 41px;
}

.small-title {
  font-size: 26px;
}

.css-1hb7zxy-IndicatorsContainer .css-1u9des2-indicatorSeparator {
  display: none !important;
}

.ck-editor.ck-rounded-corners {
  height: 100%;
}

.ck.ck-editor__main a,
.ck-link_selected .ck-editor__main a {
  color: #0000ff !important;
  background: transparent !important;
  text-decoration: underline !important;
}

.ck.ck-editor__main ul,
.ck-editor__main ul {
  list-style: disc;
  padding: 0 0 0 20px;
}

.ck.ck-editor__main ol,
.ck-editor__main ol {
  list-style: auto;
  list-style-type: decimal;
  padding: 0 0 0 20px;
}

.ck.ck-editor__main h1,
.ck.ck-editor__main h2,
.ck.ck-editor__main h3,
.ck.ck-editor__main h4,
.ck.ck-editor__main h5,
.ck.ck-editor__main h6,
.ck-editor__main h1,
.ck-editor__main h2 .ck-editor__main h3 .ck-editor__main h4 .ck-editor__main h5 .ck-editor__main h6 {
  font-weight: bold !important;
}

.ck.ck-editor__main h1,
.ck-editor__main h1 {
  font-size: 32px !important;
}

.ck.ck-editor__main h2,
.ck-editor__main h2 {
  font-size: 24px !important;
}

.ck.ck-editor__main h3,
.ck-editor__main h3 {
  font-size: 18.72px !important;
}

.ck.ck-editor__main h4,
.ck-editor__main h4 {
  font-size: 16px !important;
}

.ck.ck-editor__main h5,
.ck-editor__main h5 {
  font-size: 13.28px !important;
}

.ck.ck-editor__main h6,
.ck-editor__main h6 {
  font-size: 10.72px !important;
}

.ck.ck-editor__main p {
  font-weight: normal;
}

.ck-editor__main p img {
  display: unset;
}

.right-links-sec ul li a {
  font-size: 20px !important;
}

.text-2xl,
.text-xl .text-lg,
.text-sm {
  letter-spacing: normal !important;
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  border-color: #757575 !important;
}

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: unset !important;
  outline-offset: unset !important;
  --tw-ring-inset: unset !important;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: unset !important;
  --tw-ring-offset-shadow: unset !important;
  --tw-ring-shadow: unset !important;
  box-shadow: unset !important;
  border-color: var(--RedColor) !important;
}

.resource-details p {
  word-wrap: break-word;
  margin-top: 20px;
}

/* Page management */
.page-management-editor.post-details .ck-content {
  min-height: 400px;
}

#add-edit-modal {
  width: 90%;
  margin: 10px auto;
}

.custom-hr {
  width: 95%;
  margin: 0 auto;
  border-color: var(--commonTextColor);
}

.tbl-th-styling label {
  display: flex;
  align-items: flex-end;
}

.tbl-detail-list {
  display: flex;
  align-items: center;
}

.action-btns-member .action-desc {
  left: 20px;
  right: unset !important;
}

/* Custom-table-history Start */
.custom-tbl-td-history:nth-child(odd) {
  background: #f0f0f0;
}

.custom-tbl-td-history:nth-child(even) {
  background: #fff !important;
}

/* Custom-table-history End */
.signup-text-area {
  height: 100%;
}

.slected-chapter .right-action {
  padding-right: 30px;
}

.slected-chapter .right-action .menu-svg,
.slected-chapter .right-action .menu-svg path {
  fill: #ffffff;
  width: 35px;
  height: 35px;
}

.dropdwn-input .relative.inline-flex.w-full {
  width: 100px;
}

.dropdwn-input .relative.inline-flex.w-full .btn-dropdown {
  border-right: 0;
}

.dropdwn-input .absolute.top-full.enter-done {
  width: 100%;
}

.dropdwn-input-email .relative.inline-flex.w-full {
  width: 350px;
}

.dropdwn-input-email .relative.inline-flex.w-full .btn-dropdown {
  border-left: 0;
}

.contribution-tble .table-bg {
  background: transparent;
}

.custom-tbl-td-contribution:nth-child(odd) {
  background: #fff !important;
}

.custom-tbl-td-contribution:nth-child(even) {
  background: #fff !important;
}

/* Checkbox Custom Start */
.create-individual-members input[type="checkbox"] {
  cursor: pointer;
}

.create-individual-members input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  width: 25px;
  height: 25px;
  border: 1px solid #000000 !important;
  display: grid;
  place-content: center;
  border-radius: 0;
}

.create-individual-members input[type="checkbox"]::before {
  content: "";
  width: 15px;
  height: 15px;
  background-image: url(../public/checked-icon.svg) !important;
  background-size: contain;
  transform: scale(0);
  transform-origin: center;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: transparent !important;
}

.create-individual-members input[type="checkbox"]:focus {
  border: none !important;
  outline: none !important;
}

.create-individual-members input[type="checkbox"]:checked::before {
  transform: scale(1.2);
}

.create-individual-members input[type="checkbox"] {
  outline: none !important;
  background-color: transparent !important;
}

.create-individual-members input[type="checkbox"]:hover {
  background-color: unset !important;
}

/*  */

/* Checkbox Custom End */

/* Tab content - closed */
.tab-content {
  max-height: 0;
  -moz-transition: max-height 0.5s ease-out;
  -webkit-transition: max-height 0.5s ease-out;
  -o-transition: max-height 0.5s ease-out;
  transition: max-height 0.5s ease-out;
}

/* :checked - resize to full height */
.tab input:checked~.tab-content {
  max-height: 100%;
  transition: max-height 0.5s ease-in;
}

/* Icon */
.tab label::before {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5;
  font-size: 1.25rem;
  text-align: center;
  -webkit-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
  color: var(--bgColor);
}

/* Icon formatting - closed */
.tab input.toggle-main[type="checkbox"]+label::before {
  content: "+";
  font-weight: bold;
  border-radius: 9999px;
}

.tab input.toggle-main[type="radio"]+label::before {
  content: "+";
  font-weight: bold;
  border-radius: 9999px;
}

/* Icon formatting - open */
.tab input.toggle-main[type="checkbox"]:checked+label::before {
  color: var(--bgColor);
  content: "-";
}

.tab input.toggle-main[type="radio"]:checked+label::before {
  content: "-";
  color: var(--bgColor);
}

.communication-label::before {
  display: none !important;
}

/* Dashboard */

.prospects,
.corporateMember,
.latestenquiry,
.detailedBlock {
  font-size: 1rem;
}

.prospects {
  color: #fff;
  background-color: #0e5c9d;
}

.corporateMember {
  background-color: #cc0000;
  color: #fff;
}

.latestenquiry {
  background-color: #6b8eaa;
  color: #fff;
}

.detailedBlock {
  background-color: #c0d0d8;
  color: #000;
}

.events {
  background-color: #e4e4e4 !important;
}

.fees {
  background-color: #d2dddb !important;
}

.prospects>.title {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
}

.prospects>.title>.icon::before,
.latestenquiry>.title>.icon::before,
.corporateMember>.title>.icon::before,
.detailedBlock>.title>.icon::before {
  /* font-family: "FontAwesome"; */
  /* font-weight: 100; */
  content: "";
  position: absolute;
  font-size: 2rem;
  right: calc(100% + 30px);
  top: -3px;
  height: 40px;
  width: 40px;
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.prospects>.title>.icon::before {
  font-family: "FontAwesome";
  font-weight: 100;
  content: "\f007";
}

.corporateMember>.title>.icon::before {
  font-family: "FontAwesome";
  font-weight: 100;
  content: "\f0c0";
}

.latestenquiry>.title>.icon::before {
  content: "";
  background: url(../public/latestenquiry.png);
  background-repeat: no-repeat !important;
  background-size: 30px !important;
  background-position: center !important;
}

.detailedBlock>.title>.icon::before {
  background: url(../public/clock-icon.png);
}

.events>.title>.icon::before {
  background: url(../public/events-icon.png);
}

.fees>.title>.icon::before {
  background: url(../public/fees-collect-icon.png);
}

.prospects>.title>.icon,
.latestenquiry>.title>.icon,
.corporateMember>.title>.icon,
.detailedBlock>.title>.icon {
  position: relative;
  font-size: 1.5rem;
}

.prospects>.title,
.latestenquiry>.title,
.corporateMember>.title,
.detailedBlock>.title {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
}

.detailedBlock>.title {
  color: #000;
}

.prospects>.title>.icon,
.latestenquiry>.title>.icon,
.corporateMember>.title>.icon,
.detailedBlock>.title>.icon {
  font-size: 1.5rem;
}

.btn-edit {
  background-color: #0e5c9d;
  color: #fff;
  width: auto;
  padding: 8px 18px;
}

.inner-number {
  font-weight: 600;
  font-size: 1.7rem;
}

#membership-pricing-modal {
  width: 70%;
  margin: 50px auto;
  height: auto;
}

.active-inputs .tbl-detail-list input {
  background: #fff;
  border: 1px solid var(--commonTextColor);
}

.padding-r-0 .inputs-lists {
  padding-right: 0;
}

.tab input:checked~.tab-content.committee-info-overflow {
  overflow: unset !important;
}

.authority-limit-new-model #add-edit-modal {
  width: 75%;
}

.prasantage-input {
  position: relative;
}

.prasantage-input::after {
  content: "%";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 20px;
  border-left: 1px solid var(--commonTextColor);
  height: 99%;
  color: var(--commonTextColor);
  padding: 15px 15px 15px 20px;
}

.sign-icon-input {
  margin-bottom: 0px;
}

.sign-icon-input .dollar-input {
  position: relative;
  color: var(--commonTextColor);
}

.sign-icon-input input {
  padding-left: 30px;
  font-size: 18px;
}

.sign-icon-input .dollar-input::after {
  content: "$";
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-22%);
  font-size: 18px;
  height: 100%;
  color: var(--commonTextColor);
  padding-left: 15px;
}

.add-exco-member-popup #add-exco-member-modal .main-model-sec {
  width: 60%;
}

.add-edite-employee-popup #show-Add-edit-profile-modal .main-model-sec,
.add-edite-profile-popup #show-individual-profile-modal .main-model-sec {
  width: 80%;
  padding-bottom: 30px;
}

.add-edite-f-name .btn-dropdown,
.individual-f-name .btn-dropdown {
  border-right: 0;
}

.add-edit-profile-email .btn-dropdown,
.individual-email .btn-dropdown {
  border-left: 0;
}

/* .annual-member-fee .border-section-main {
  border-top: 0;
} */

.membership-task-list-process-sec #send-for-approve-modal .main-model-sec {
  width: 40%;
}

.membership-task-list-process-sec #send-payment-link-modal .main-model-sec {
  width: 40%;
}

.membership-task-list-process-sec #send-quotation-modal .main-model-sec {
  width: 40%;
}

/* Prasad 29/08/22 */
.membership-task-list-process-sec #membership-directory-enquiry-modal .main-model-sec {
  width: 50%;
}

.dropdown-option-section-list {
  max-height: 300px;
}

.dropdown-option-section-list button {
  width: 100%;
  word-break: break-all;
  text-align: left;
}

.membership-task-list-process-sec #send-invoice-modal .main-model-sec {
  width: 40%;
}

.membership-task-list-process-sec #add-edit-strategic-member-modal .main-model-sec {
  width: 80%;
  height: auto;
}

.custom-input,
input,
.dropdwn-input {
  color: #757575 !important;
  height: 57px;
  outline: none !important;
}

.form-checkbox:checked {
  background-color: transparent !important;
}

.quatation-send-btn button {
  width: 233px;
  font-size: 20px;
}

/* patnership page start */
.filter-info {
  display: flex;
  justify-content: space-between;
}

.btn-two .patner-search {
  display: flex;
  justify-content: space-between;
}

.btn-two .patner-search .search {
  margin-right: 7px;
}

/* Membership Task List Input Start */
.dollar-sign {
  position: relative;
  display: inline-block;
}

.pricing-table-sec input {
  width: 100%;
}

.dollar-sign:before {
  content: "$";
  position: absolute;
  top: 51%;
  left: 5px;
  transform: translateY(-50%);
  font-size: 14px;
  color: var(--commonTextColor);
  padding-left: 10px;
}

.percentage-sign {
  position: relative;
  display: inline-block;
}

.percentage-sign:after {
  content: "%";
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
  font-size: 14px;
  color: var(--commonTextColor);
  padding-left: 10px;
}

.percentage-sign.with-border-line:after {
  right: 22px;
  font-size: 18px;
}

.percentage-sign.with-border-line:before {
  position: absolute;
  content: "";
  height: 100%;
  right: 55px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  background: var(--commonTextColor);
}

/* Membership Task List Input End */

.avaibility-table .tbl-th {
  padding: 15px 0;
}

.css-6j8wv5-Input,
.css-qc6sy-singleValue,
.css-14el2xx-placeholder {
  color: #000000 !important;
  font-size: 1rem;
}

.program-deletebtn .menu-svg {
  width: 35px;
  height: 35px;
}

.date-pickers::-webkit-datetime-edit-year-field:not([aria-valuenow]),
.date-pickers::-webkit-datetime-edit-month-field:not([aria-valuenow]),
.date-pickers::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent !important;
}

.date-pickers::-webkit-calendar-picker-indicator {
  background: url(../public/calender.svg) !important;
  background-image: url(../public/calender.svg) !important;
  background-position: center !important;
  background-size: contain !important;
}

/* Checkbox Custom Start */
.custom-checkbox-set input[type="checkbox"],
input[type="checkbox"].check-box-custom {
  cursor: pointer;
}

.custom-checkbox-set input[type="checkbox"],
input[type="checkbox"].check-box-custom {
  -webkit-appearance: none !important;
  appearance: none !important;
  background-color: transparent !important;
  margin: 0 !important;
  width: 22px !important;
  height: 22px !important;
  /* border: 1px solid #000000 !important; */
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(117 117 117 / var(--tw-ring-opacity));
  display: grid !important;
  place-content: center !important;
  border-radius: 0 !important;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.custom-checkbox-set input[type="checkbox"]::before,
input[type="checkbox"].check-box-custom:before {
  content: "" !important;
  width: 15px !important;
  height: 15px !important;
  background-image: url(../public/checked-icon.svg) !important;
  background-size: contain !important;
  transform: scale(0) !important;
  transform-origin: center !important;
  transition: 120ms transform ease-in-out !important;
  box-shadow: inset 1em 1em var(--form-control-color) !important;
  background-color: transparent !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.custom-checkbox-set input[type="checkbox"]:focus,
input[type="checkbox"].check-box-custom:focus {
  border: 0px solid transparent !important;
  outline: 0px solid transparent !important;
  outline-offset: 0 !important;
  width: 22px !important;
  height: 22px !important;
}

.custom-checkbox-set input[type="checkbox"]:checked::before,
input[type="checkbox"].check-box-custom:checked::before {
  transform: scale(1.2) !important;
}

.custom-checkbox-set input[type="checkbox"],
input[type="checkbox"].check-box-custom {
  outline: none !important;
  background-color: transparent !important;
  background-image: none !important;
  appearance: none !important;
  border: none !important;
}

.custom-checkbox-set input[type="checkbox"]:hover,
input[type="checkbox"].check-box-customHover {
  background-color: unset !important;
}

.disabled-checkbox input[type="checkbox"] {
  cursor: not-allowed !important;
  background-color: #dfdfdf !important;
  appearance: none !important;
  border: none !important;
}

.disabled-checkbox input[type="checkbox"]:focus {
  cursor: not-allowed !important;
  background-color: #dfdfdf !important;
  appearance: none !important;
}

.disabled-checkbox input[type="checkbox"]:hover {
  cursor: not-allowed !important;
  background-color: #dfdfdf !important;
  appearance: none !important;
}

.disabled-checkbox input[type="checkbox"]:checked {
  background-image: none !important;
}

.swal2-icon.swal2-success {
  border-color: #a5dc86 !important;
  color: #a5dc86 !important;
}

.swal2-icon.swal2-warning {
  border-color: #facea8 !important;
  color: #f8bb86 !important;
}

.disabled-input input,
.disabled-input textarea,
.disabled-input .custom-checkbox-select,
.disabled-input .css-e56m7-control {
  background: #e9e9ea !important;
  cursor: not-allowed !important;
  opacity: 1 !important;
}

.disabled-input .date-pickers:before {
  position: absolute;
  content: "";
  background: url(../public/date-pic-icon.png);
  height: 24px;
  width: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  right: 13px;
}

.company-logo-cropper .individual-profile-create-cropper .img-logo-upload img {
  min-width: 50%;
  max-width: 50%;
  margin: 0 auto 30px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(../public/date-pic-icon.png) no-repeat;
  width: 21px;
  height: 21px;
  border-width: thin;
  position: relative;
  right: -6px;
}

.adminaccount-info-croper .img-logo-upload {
  width: 55%;
  margin: 0 auto;
}

.adminaccount-info-croper .img-logo-upload img {
  width: 350px;
  margin: 0px auto 40px;
}

.adminaccount-info-croper #cropimg_undefined {
  height: 250px;
  width: 350px;
  object-fit: cover;
  object-position: center;
}

.adminaccount-info-croper.company-logo-cropper .img-logo-upload p {
  width: 100%;
  padding: 10px 0 0;
}

.company-logo-cropper .img-logo-upload p {
  width: 70%;
  margin: 20px auto 0;
}

.css-1s2u09g-control {
  border-radius: 0 !important;
  height: 57px;
}

.css-6j8wv5-Input,
.css-6j8wv5-Input input {
  height: 32px;
}

.css-1pahdxg-control {
  border-radius: 0 !important;
}

.add-edit-project-bottom-table table thead tr th,
.add-edit-project-bottom-table table tbody tr td {
  border: 1px solid var(--commonTextColor);
  text-align: center;
}

.add-edit-project-bottom-table table thead tr th .tble-th-txt.text-left {
  text-align: center;
}

.radio-table-sec .tble-td-txt {
  padding: 8px 10px;
}

.approve-icon-bg {
  width: 45px;
  height: 45px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.approve-icon-bg .approve-icon {
  display: block;
  min-width: 20px;
  min-height: 20px;
}

.input-sec-texts:nth-child(even) {
  padding-left: 45px;
}

.input-sec-texts:nth-child(odd) {
  padding-right: 45px;
}

.left-border-none input {
  border-left: none;
}

.banner-manage .img-logo-upload p {
  font-size: 18px;
}

/* Web layout Styles */

.big-title-font {
  font-size: 50px;
}

.inner-title-font {
  font-size: 34px;
}

.inner-title-font-midium {
  font-size: 28px;
}

.inner-title-font-small {
  font-size: 22px;
}

.big-bullet-points {
  font-size: 16px;
}

.inner-title-font-small-button {
  font-size: 18px;
}

.common-fonts-size {
  font-size: 16px;
}

/* Header Start */

.main-header-section {
  padding: 0 110px;
  z-index: 1;
  background: #fff;
}

.main-header-section {
  position: relative;
}

.stiky-header {
  position: sticky;
  top: 0;
  box-shadow: 0px 0px 10px 0px var(--commonTextColor);
}

.border-top-menu {
  min-width: 215px;
  border-top: 2px solid #e5e7eb;
  padding-top: 10px;
}

.border-top-menu.active-menu,
.border-top-menu:hover {
  border-top: 2px solid var(--bgColor);
}

.border-top-menu.active-menu .menu-btn {
  color: var(--bgColor);
}

.border-top-menu.active-menu .menu-btn .arrow-icon {
  fill: var(--bgColor);
}

.border-top-menu .block {
  width: calc(100vw - 17px);
}

/* Header End */
.header-searchbar-section {
  height: 65px;
}

.main-header-section .main-logo img.main-logo-header {
  height: 65px;
}

/* transperent Header */

.main-header-section.home-header {
  background: transparent;
}

.main-header-section.home-header .main-logo img {
  filter: brightness(0) invert(1);
}

.main-header-section.home-header .home-icon-change {
  background: #fff;
}

.main-header-section.home-header .home-icon-change img {
  filter: contrast(1%);
}

.main-header-section.home-header .btn-lighblue-border {
  border: 2px solid #fff;
  transition: 0.5s;
  color: #fff;
}

.main-header-section.home-header .border-top-menu .menu-btn {
  color: #fff;
}

.main-header-section.home-header .border-top-menu svg {
  fill: #fff;
}

.main-header-section.home-header .border-top-menu {
  transition: 0.5s;
}

.main-header-section.home-header .border-top-menu.active-menu,
.main-header-section.home-header .border-top-menu:hover {
  border-top: 2px solid var(--commonTextColor);
  transition: 0.5s;
}

.main-header-section.home-header .border-top-menu.active-menu .menu-btn {
  color: #fff;
}

.main-header-section.home-header .border-top-menu.active-menu svg {
  fill: #fff;
}

.main-header-section.home-header .border-top-menu:hover {
  border-top: 2px solid var(--commonTextColor);
  transition: 0.5s;
}

.main-header-section.home-header.stiky-header {
  background: #fff;
}

.main-header-section.home-header.stiky-header .main-logo img.main-logo-header {
  filter: unset;
}

.main-header-section.home-header.stiky-header .home-icon-change {
  background: #94a3b8;
}

.main-header-section.home-header.stiky-header .home-icon-change img {
  filter: unset;
}

.main-header-section.home-header.stiky-header .btn-lighblue-border {
  border: 2px solid var(--bgColor);
  transition: 0.5s;
  color: var(--bgColor);
}

.main-header-section.home-header.stiky-header .border-top-menu .menu-btn {
  color: var(--commonTextColor);
}

.main-header-section.home-header.stiky-header .border-top-menu .menu-btn:hover {
  color: #b91c1c;
}

.main-header-section.home-header.stiky-header .border-top-menu svg {
  fill: var(--commonTextColor);
}

.main-header-section.home-header.stiky-header .border-top-menu .menu-btn:hover .arrow-icon {
  fill: #b91c1c;
}

.main-header-section.home-header .login-user-section img {
  filter: contrast(10);
}

.main-header-section.home-header .login-user-section hr {
  background: #fff;
}

.main-header-section.home-header.stiky-header .login-user-section img {
  filter: unset;
}

.main-header-section.home-header.stiky-header .login-user-section hr {
  background: var(--commonTextColor);
}

/* Footer Section Start */
.footer-section {
  padding: 0 111px;
  background: var(--commonTextColor);
  z-index: 1;
}

.footer-section .footer-link {
  transition: 0.5s;
}

.footer-section .footer-link:hover {
  color: var(--bgColor);
  transition: 0.5s;
}

.footer-section form .btn-lighblue {
  height: 57px;
  min-width: 128px;
}

/* Footer Section End */

/* Inner page  */

.main-title {
  padding: 0 111px 4%;
  position: relative;
  background-size: 100% !important;
  width: 100% !important;
  height: 250px !important;
}

.main-title.articles-bg {
  padding: 0 111px 4%;
}

.page-header {
  position: relative;
  width: 100%;
  height: 100%;
}

.page-header h2 {
  position: absolute;
  bottom: 0px;
}

.slick-dots {
  bottom: 10px !important;
}

.slick-dots li.slick-active button:before {
  color: #b02318 !important;
}

.slick-dots li button:before {
  color: #fff !important;
  opacity: 1 !important;
}

.common-input-dropdown {
  position: relative;
  display: block;
}

.common-input-dropdown::before {
  cursor: pointer;
  content: "";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: var(--commonTextColor);
  background: url(../public/downarrow.png);
  z-index: 99999;
  opacity: 1;
  width: 12px;
  height: 8px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.4;
}

#add-edit-strategic-member-modal {
  width: 80%;
  margin: 0 auto;
}

#add-edit-strategic-member-modal .main-model-sec {
  position: relative;
  overflow: unset;
}

#add-edit-strategic-member-modal .main-model-sec .close-button {
  position: absolute;
  right: 20px;
  top: 20px;
}

.mobileview {
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0px auto;
  height: 668px;
  width: 343px;
}

.mobileview img {
  position: absolute;
  top: 0;
  bottom: -20px;
  left: -22px;
  right: 0;
  /* margin: auto; */
  z-index: -1;
  width: 343px;
}

.mobileview iframe {
  overflow-y: auto;
  z-index: 9;
  border-radius: 20px 20px;
  margin-top: 21px;
}

.mobileview .inner-title-font-small-button {
  font-size: 12px;
}

.close-sign {
  position: absolute;
  right: 50px;
  top: 25px;
  background: #fff;
  border-radius: 100%;
}

.form-checkbox:checked {
  background-size: 75% !important;
}

.top-header-icon {
  min-height: 65px;
  min-width: 65px;
}

.main-page-section {
  padding: 0 111px;
}

/* Prasad Changes */
.my-custom-scrollbar {
  position: relative;
  height: 300px;
  overflow: auto;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  min-height: 250px;
}

.speaker-img-cropper .img-logo-upload img {
  height: 200px;
  width: 200px;
  margin: 0 auto 50px;
  object-fit: cover;
}

.speaker-img-cropper .btn-red-border {
  width: 70%;
  margin: 0 auto;
  /* padding: unset; */
}

.simple-txt-img.speaker-img-cropper .img-logo-upload img {
  height: 200px;
  width: 100%;
}

.laoutImage-part.speaker-img-cropper .img-logo-upload img {
  height: auto;
  width: 100%;
}

.listing-img-part.speaker-img-cropper .img-logo-upload img {
  height: 150px;
  width: 150px;
}

.listing-img-part.speaker-img-cropper p {
  font-size: 16px;
}

.partner-tbl-sec {
  max-height: 370px;
  margin-bottom: 60px;
  overflow: hidden;
  overflow-y: auto;
}

/* Table Scolling css */
.fixed_header {
  width: 100%;
  background: #fff;
}

.fixed_header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  max-height: 360px;
}

.fixed_header thead {
  background: var(--bgColor);
  background-color: var(--bgColor);
}

.fixed_header th,
.fixed_header td {
  padding: 20px;
  text-align: center;
}

.project-tbl .custom-tbl-td td:first-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.partner-tbl .custom-tbl-td td:first-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.stretegic-member-tbl .custom-tbl-td td:first-child {
  text-align: left;
}

.border-section-main {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 30px;
}

.border-section-main:nth-last-child(2) {
  border: none;
}

.startupmember-sec .border-section-main:nth-last-child(2) {
  border: 1px solid #e0e0e0;
}

/* Table Scolling css */

.email-template-manage-sec .overflow-x-auto {
  overflow-x: unset !important;
}

.email-template-manage-sec table {
  display: inline-block;
  width: 100%;
  table-layout: fixed;
}

.email-template-manage-sec table thead {
  display: inline-block;
  width: 100%;
}

.email-template-manage-sec table thead tr {
  display: flex;
  width: 100%;
}

.email-template-manage-sec table thead tr th:nth-child(1) {
  width: 25%;
}

.email-template-manage-sec table thead tr th:nth-child(2) {
  width: 20%;
}

.email-template-manage-sec table thead tr th:nth-child(3) {
  width: 20%;
}

.email-template-manage-sec table thead tr th:nth-child(4) {
  width: 25%;
}

.email-template-manage-sec table thead tr th:nth-child(5) {
  width: 10%;
}

.email-template-manage-sec table tbody {
  display: block;
}

.email-template-manage-sec table tbody .custom-tbl-td {
  display: flex;
  width: 100%;
}

.email-template-manage-sec table tbody tr td:nth-child(1) {
  width: 25%;
}

.email-template-manage-sec table tbody tr td:nth-child(2) {
  width: 20%;
  word-wrap: break-word;
  table-layout: fixed;
}

.email-template-manage-sec table tbody tr td:nth-child(3) {
  width: 20%;
}

.email-template-manage-sec table tbody tr td:nth-child(4) {
  width: 25%;
}

.email-template-manage-sec table tbody tr td:nth-child(5) {
  width: 10%;
}

.email-template-manage-sec table tbody tr td {
  white-space: break-spaces;
}

.membership-task-list-process-sec.add-page-model-sec #add-edit-strategic-member-modal .main-model-sec {
  overflow-y: unset;
}

.edit-field.border-red input {
  border-color: var(--bgColor);
}

.edit-field.input-height-set input {
  height: 40px;
}

.edit-field.input-height-set.center-text input {
  text-align: center;
}

/* Managetags Start */
.tags-manage-sec table thead tr th:nth-child(1) {
  width: 30%;
}

.tags-manage-sec table thead tr th:nth-child(2) {
  width: 40%;
}

.tags-manage-sec table thead tr th:nth-child(3) {
  width: 20%;
}

.tags-manage-sec table thead tr th:nth-child(4) {
  width: 10%;
}

.tags-manage-sec table tbody tr td:nth-child(1) {
  width: 30%;
}

.tags-manage-sec table tbody tr td:nth-child(2) {
  width: 40%;
  word-wrap: break-word;
  table-layout: fixed;
}

.tags-manage-sec table tbody tr td:nth-child(3) {
  width: 20%;
}

.tags-manage-sec table tbody tr td:nth-child(4) {
  width: 10%;
}

.css-g8u311-control {
  height: 57px;
  border: 1px solid #000000 !important;
}

.listing-img-part .ReactCrop__child-wrapper>img {
  min-height: 200px;
  min-width: 200px;
}

.govt-program-s-member ul.project-list {
  list-style-type: disclosure-closed;
  padding-left: 25px;
  font-size: 16px;
}

.cursor-not-allowed input[type="checkbox"].checkbox-not-allowed {
  cursor: not-allowed !important;
  background: #ebebeb !important;
}

.setting-delete-button svg {
  width: 35px;
  height: 35px;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
  font-size: 1rem !important;
}

.settings-section {
  position: relative;
}

.bg-top-menu {
  position: relative;
  content: "";
  height: 85px;
  width: 68px;
  background: #f0f0f1;
  z-index: 0;
  margin-top: -85px;
  margin-left: 20px;
}

.btn-settings {
  /* transition: 0.5s; */
}

.active-btn {
  height: 95px;
  width: 68px;
  background: #f0f0f1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 15px;
  position: absolute;
  left: 18px;
  top: -15px;
  /* transition: 0.5s; */
  margin-right: 70px;
}

.settings-section .sign-icon-input .dollar-input::after {
  left: 10px;
}

.settings-section .sign-icon-input input {
  padding-left: 70px;
}

.settings-section .sign-icon-input .sign-sec {
  position: relative;
}

.settings-section .sign-icon-input .sign-sec::before {
  position: absolute;
  height: 100%;
  width: 1px;
  content: "";
  left: 60px;
  background: var(--commonTextColor);
}

.required-btn .toggle-btn-sec {
  margin-left: 20px;
  width: auto;
}

.required-btn .toggle-btn-sec .member-directory-top-boxes {
  font-size: 16px;
  color: #3b4356;
}

.required-btn .toggle-btn-sec .toggle-switch-part {
  justify-content: space-between;
}

.validation-text-s {
  font-size: 14px;
}

.text-area-space {
  padding-top: 18px !important;
  color: var(--commonTextColor);
}

.edit-detail-price-text {
  font-size: 13px;
}

.answer-input .form-radio {
  border-color: #c2c2c2 !important;
}

.answer-input .form-radio:checked {
  border-color: #000000 !important;
}

.answer-input .custom-checkbox-set input[type="checkbox"] {
  border: 1px solid #c2c2c2 !important;
}

.mandatory-request {
  margin-bottom: 10px;
}

.mandatory-request .toggle-switch-part {
  justify-content: flex-end !important;
}

.mandatory-request.required-btn .toggle-btn-sec .member-directory-top-boxes {
  color: var(--commonTextColor);
  font-size: 21px;
}

.dropdown-question,
.dropdown-multi-question {
  position: relative;
}

.dropdown-question::after {
  position: absolute;
  content: "";
  background: url(../public/dropdown-icon.png);
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.dropdown-multi-question::after {
  position: absolute;
  content: "";
  background: url(../public/multi-dropdown-icon.png);
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.gst-edit-btn button {
  margin: 0 auto !important;
}

.track-number-part {
  font-size: 20px;
}

.lession-section {
  box-shadow: 0px 0px 20px -2px #c3d6eb;
  margin-bottom: 20px;
}

.fixed_header tbody.globle-setting-tbody {
  max-height: 270px;
}

.fixed_header tbody.header-not-fixed-tbody {
  max-height: unset;
}

.custom-tbl-td td {
  font-size: 16px;
}

/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button, */
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* .generate-credit-note-popup #generate-credit-note-modal{
  width: 40%;
  margin: 0 auto;
} */
.generate-credit-note-popup #generate-credit-note-modal {
  width: 70%;
  margin: 30px auto;
}

.generate-credit-note-popup #generate-credit-note-modal .modal-top-title-sections {
  border-bottom: 1px solid #0000004d;
  padding-bottom: 20px;
}

#cancel-booking-modal {
  width: 40%;
  margin: 30px auto;
  padding: 20px 0;
}

.percentage-sign input.custom-percentage-input {
  padding-right: 50px;
}

/* View Booking Start */
.View-booking-sec .tble-th-txt {
  font-size: 18px;
}

.View-booking-sec .custom-tbl-td td {
  font-size: 16px;
}

/* Custom Accordian Start  */

.event-booking-tabs .custom-card {
  padding: 0 0 20px;
  border-bottom: 2px dashed #d6d7d9;
  margin-bottom: 22px;
}

.event-booking-tabs .custom-card:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.custom-accordian input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.custom-accordian .tabs {
  overflow: hidden;
}

.custom-accordian .tab {
  width: 100%;
  overflow: hidden;
}

.custom-accordian .tab-label {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
}

.custom-accordian .tab-label::after {
  content: "+";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
  left: 0px;
  position: absolute;
}

.custom-accordian .tab-content {
  max-height: 0;
  padding: 0 1em;
  transition: all 0.35s;
  color: var(--commonTextColor);
}

.custom-accordian .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  cursor: pointer;
}

.custom-accordian input:checked+.tab-label::after {
  content: "-";
}

.custom-accordian input:checked~.tab-content {
  max-height: 100vh;
  /* padding: 10px 5px; */
  padding-top: 5px;
}

/* Custom Accordian End  */

/* View-page Table Start */

.view-page-table-section table th:nth-child(5) {
  text-align: center;
}

.view-page-table-section table td:nth-child(5) div {
  justify-content: center;
}

.view-page-table-section table td:nth-child(5) div button:first-child {
  margin-right: 15px;
}

/* View-page Table End */
.css-12jo7m5 {
  font-size: 14px !important;
}

.text-xl {
  font-size: 16px !important;
}

table .tble-th-txt {
  font-size: 18px;
}

.tiny-editor-sec,
#tinymce {
  color: #848484 !important;
}

.tiny-editor-sec .tox-tinymce {
  border: 1px solid #c4c4c4 !important;
  border-radius: 0 !important;
}

.ck-editor__main table tr td {
  padding: 0 7px;
  border: 1px solid var(--commonTextColor);
}

.cropped-img-view img {
  border: 1px solid var(--commonTextColor);
}

.tbl-detail-list input.disabled-input-table {
  background: #f2f2f2;
  border: none;
}

.outstanding-invoice-detail-modal textarea {
  height: auto;
}

.outstanding-invoice-detail-modal #outstandingInvoices {
  width: 60%;
  margin: 0 auto;
}

.outstanding-invoice-detail-modal #outstandingInvoices .main-model-sec .table-title-txt {
  padding-left: 0;
}

.outstanding-invoice-detail-modal #outstandingInvoices .main-model-sec .close-sign {
  right: 20px;
}

.add-record-popup-section {
  padding: 0 45px 50px;
}

.new-btn {
  display: flex;
  justify-content: flex-end;
}

.left-btn {
  padding-right: 2rem;
}

.custom-checkbox-select .css-1lb4uql-control {
  height: 0;
  overflow-y: auto;
}

.custom-checkbox-select .css-e56m7-control,
.custom-checkbox-select .css-1rjh3ff-control,
.custom-checkbox-select .css-1lb4uql-control,
.css-12zf3ro-control,
.css-12zf3ro-control:hover,
.custom-time-picker {
  min-height: 57px !important;
  border: 1px solid #757575 !important;
}

.custom-checkbox-select .css-12zf3ro-control,
.btn-dropdown:focus {
  border: 1px solid var(--RedColor) !important;
}

.css-11xsx5k-control {
  height: 57px;
  border: 1px solid #000000 !important;
}

.css-qbdosj-Input input,
.css-166bipr-Input,
.css-166bipr-Input input {
  height: 38px !important;
}

.css-1fdsijx-ValueContainer,
.css-1h01tm3-Input {
  height: 45px;
}

.custom-input input {
  height: auto !important;
}

.send-clarification-model .table-title-txt {
  padding-left: 0;
}

@media only screen and (max-width: 1800px) {
  /* .custom-checkbox-select .css-e56m7-control,
  .custom-checkbox-select .css-1rjh3ff-control,
  .custom-checkbox-select .css-1lb4uql-control,
  .css-12zf3ro-control,
  .css-12zf3ro-control:hover,
  .custom-time-picker {
    min-height: 52px !important;
  } */
}

.header-area-height-set {
  height: 89px;
}

.css-1jqq78o-placeholder,
.css-qbdosj-Input,
.css-1dimb5e-singleValue {
  font-size: 16px !important;
  line-height: initial;
}

.css-nne2zq-menue {
  font-size: 16px !important;
}

/* Responsive -Styles */
@media only screen and (max-width: 1800px) {
  .track-number-part {
    font-size: 15px;
  }

  .cursor-pointer {
    height: 25px !important;
  }
}

@media only screen and (max-width: 1720px) {
  .orgprofile-codes {
    min-height: 158px !important;
  }
}

@media only screen and (max-width: 1700px) {

  .filter-lable,
  .font-22 {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 1658px) {
  .orgprofile-codes {
    min-height: 183px !important;
  }
}

@media only screen and (max-width: 1650px) {
  .css-12jo7m5 {
    font-size: 14px !important;
  }

  .btn {
    padding: 11px 15px;
  }

  .header-txt {
    font-size: 18px;
  }

  .main-header-section .main-logo img.main-logo-header {
    height: 55px;
  }

  .css-g8u311-control {
    height: 52px;
  }

  input,
  .btn-dropdown,
  select,
  textarea,
  .css-11xsx5k-control,
  .css-qbdosj-Input {
    height: 52px !important;
  }

  .custom-checkbox-select .css-qbdosj-Input {
    height: 0 !important;
  }

  .main-header-section.home-header .border-top-menu svg {
    margin-left: 0;
  }

  .main-header-section.home-header.stiky-header .border-top-menu .menu-btn {
    font-size: 16px;
  }

  .header-searchbar-section {
    height: 55px;
  }

  .main-header-section input {
    height: 40px;
  }

  .top-header-icon {
    min-height: 55px;
    min-width: 55px;
  }

  .border-top-menu {
    min-width: 152px;
  }

  .css-g8u311-control {
    height: 52px;
  }

  .validation-text-s {
    font-size: 12px;
  }

  .text-area-space {
    padding-top: 14px !important;
  }

  .main-body-section {
    padding: 25px 35px;
  }

  .finance-body-sec {
    padding: 20px;
  }

  .custom-card {
    padding: 30px 30px 24px;
  }

  .custom-card2 {
    padding: 20px 20px 14px;
  }

  .breadcome-sec .left-title-sec p {
    font-size: 24px;
  }

  .breadcome-sec .right-links-sec li a {
    font-size: 16px;
  }

  .menu-icon-img p.menu-title {
    font-size: 16px !important;
  }

  .small-title {
    font-size: 18px;
  }

  .sub-menu-title {
    font-size: 15px;
  }

  .filter-lable,
  .font-22 {
    font-size: 16px !important;
  }

  .tble-th-txt {
    font-size: 20px;
  }

  .tble-td-txt {
    font-size: 18px;
  }

  .css-6j8wv5-Input,
  .css-6j8wv5-Input input {
    height: 27px;
  }

  .custom-input input {
    height: 15px;
  }

  .header-txt {
    font-size: 14px;
  }

  .charts-of-accounts-tble table thead th {
    padding: 10px 15px;
  }

  .text-xl,
  .tble-th-txt {
    font-size: 16px !important;
  }

  .tble-td-txt {
    font-size: 14px !important;
  }

  .table-title-txt {
    font-size: 24px;
  }

  /* .img-logo-upload img {
    height: 240px;
  } */
  .right-links-sec ul li a {
    font-size: 14px !important;
  }

  .img-logo-upload p,
  .btn-dropdown span,
  .date-time-sec-resources input,
  .time-display span {
    font-size: 14px !important;
    color: var(--commonTextColor);
  }

  .date-pickers,
  .custom-time-picker {
    height: 52px;
    font-size: 14px !important;
  }

  .btn-lighblue,
  .btn-lighblue-border,
  .btn-lighblue,
  .btn-lighblue-border,
  .btn-gray,
  .tox .tox-button--secondary {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: initial !important;
  }

  .css-14el2xx-placeholder {
    font-size: 16px !important;
    padding-left: 7px;
  }

  .custom-checkbox-select .css-e56m7-control,
  .custom-checkbox-select .css-1rjh3ff-control,
  .custom-time-picker {
    min-height: 50px;
  }

  .sign-icon-input .dollar-input::after {
    transform: translateY(-26%);
  }

  .dollar-sign:before {
    left: 0px;
    font-size: 14px;
  }

  .delete-text-line-after::after {
    left: 45%;
  }

  .delete-text-line-before::before {
    left: 43%;
  }

  .pricing-table-sec input {
    width: 100%;
    text-align: left;
    padding-left: 25px;
  }

  .percentage-sign input {
    text-align: center;
    padding-left: 7px;
    padding-right: 20px;
  }

  .percentage-sign:after {
    right: 9px;
    font-size: 15px;
  }

  .communication-label.contribution-title {
    font-size: 16px !important;
  }

  .img-logo-upload .btn-lighblue-border {
    display: inline;
  }
}

@media only screen and (max-width: 1631px) {
  .view_pdf {
    min-width: -webkit-fill-available;
    display: flex;
    /* justify-content: space-around; */
  }
}

@media only screen and (max-width: 1500px) {
  .css-12jo7m5 {
    font-size: 14px !important;
  }

  .main-header-section .main-logo img.main-logo-header {
    height: 45px;
  }

  .header-searchbar-section {
    height: 45px;
  }

  .top-header-icon {
    min-height: 45px;
    min-width: 45px;
  }

  .text-xl {
    font-size: 16px !important;
  }

  .tble-th-txt {
    font-size: 14px !important;
  }

  .header-area-height-set {
    height: 73px;
  }
}

@media only screen and (max-width: 1480px) {
  .add-question-part .icon-left img {
    min-height: 50px;
    min-width: 50px;
    max-height: 50px;
    max-width: 50px;
  }

  .css-12jo7m5 {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 1367px) {
  .cursor-pointer {
    height: 25px !important;
  }

  .main-header-section,
  .main-page-section,
  .footer-section {
    padding: 0 82px;
  }

  .main-title {
    padding: 0 82px 4%;
  }

  .main-header-section .main-logo img.main-logo-header {
    height: 45px;
  }

  .main-header-section {
    padding: 0 82px;
  }

  .text-area-space {
    padding-top: 14px;
  }
}

@media only screen and (max-width: 1366px) {
  .payment-view-approve-modal #paymentviewapprove {
    width: 70%;
  }

  .tbl-th {
    padding: 10px 0;
  }

  .custom-tbl-td td {
    font-size: 14px;
  }

  .main-body-section {
    padding: 25px;
  }

  .text-xl,
  .tble-th-txt {
    font-size: 14px !important;
  }

  .dollar-sign:before,
  .percentage-sign:after {
    font-size: 14px;
  }

  .menu-icon-img p.menu-title,
  .sub-menu-title {
    font-size: 14px;
    margin-top: 3px;
  }

  /* .img-logo-upload img {
    height: 195px;
  } */
  .authority-limit-new-model #add-edit-modal {
    width: 80%;
  }

  .add-exco-member-popup #add-exco-member-modal .main-model-sec {
    width: 70%;
  }

  .tab-font-date label {
    font-size: 14px;
  }

  .tab-font-date input {
    font-size: 14px;
  }

  .desktop-banner .main-header-section,
  .desktop-banner .footer-section {
    padding: 0 50px;
  }

  .desktop-banner .main-header-section .main-logo img.main-logo-header {
    height: 45px;
  }

  .desktop-banner .main-header-section input {
    height: 40px;
  }

  .desktop-banner .main-header-section .header-searchbar-section {
    height: 45px;
    margin-right: 30px;
  }

  .desktop-banner .main-header-section .btn-lighblue,
  .desktop-banner .main-header-section .btn-lighblue-border {
    height: 45px;
    width: unset;
    padding: 0 15px;
  }

  .desktop-banner .main-header-section .btn-lighblue-border {
    margin-left: 30px;
  }

  .desktop-banner .after-login-icon-change {
    margin-left: 30px;
  }

  .desktop-banner .main-header-section .after-login-icon-change .home-icon-change {
    min-width: 45px;
    min-height: 45px;
  }

  .desktop-banner .border-top-menu {
    min-width: 140px;
  }

  .desktop-banner .main-page-section {
    padding: 0 50px !important;
  }

  .desktop-banner .main-title {
    padding: 0 50px 4%;
  }

  .desktop-banner .footer-section form input {
    height: 45px;
  }

  .desktop-banner .footer-section form .btn-lighblue {
    height: 45px;
    min-width: 80px;
  }

  .edit-detail-price-text {
    font-size: 12px;
  }

  .btn-lighblue,
  .btn-lighblue-border,
  .btn-lighblue,
  .btn-lighblue-border,
  .btn-gray,
  .tox .tox-button--secondary {
    height: 52px;
  }
}

@media only screen and (max-width: 1279px) {
  .Button_res {
    display: flex !important;
    justify-content: space-between !important;
  }
}

@media only screen and (max-width: 1235px) {
  .main-body-section {
    padding: 15px;
  }

  .tab-font-date label {
    min-height: 42px;
    display: inline-block;
  }

  .tab-font-date input {
    font-size: 12px;
  }

  .charts-of-accounts-tble table thead th,
  .charts-of-accounts-tble table tbody td {
    white-space: nowrap !important;
  }
}

@media only screen and (max-width: 1200px) {
  .adminaccount-info-croper .img-logo-upload {
    width: 80%;
  }

  .genrate-report-button span {
    font-size: 14px !important;
  }

  .check-box-sec {
    align-items: center;
  }

  .email-template-manage-sec table tbody tr td:nth-child(5) {
    width: unset;
  }

  .btn-lighblue,
  .btn-lighblue-border,
  .btn-lighblue,
  .btn-lighblue-border,
  .btn-gray,
  .tox .tox-button--secondary {
    height: 45px;
  }

  .main-header-section {
    padding: 0 50px;
  }

  .main-header-section .main-logo img.main-logo-header {
    height: 45px;
  }

  .css-g8u311-control {
    height: 45px;
  }

  input,
  .btn-dropdown,
  select,
  textarea {
    height: 52px;
  }

  .text-area-space {
    padding-top: 10px;
  }

  .pagination-btn {
    min-width: 250px;
  }

  .text-xl,
  .tble-th-txt,
  .tble-td-txt {
    font-size: 16px !important;
  }

  .text-2xl {
    font-size: 18px !important;
  }

  .add-exco-member-popup #add-exco-member-modal .main-model-sec {
    width: 80%;
  }

  .fixed_header th,
  .fixed_header td {
    padding: 10px;
  }

  .fixed_header tbody {
    max-height: 305px;
  }

  .dollor-sign::after {
    padding: 10px 17px 15px 5px;
  }

  .prasantage-input::after {
    padding: 10px 7px 15px 15px;
  }
}

@media only screen and (max-width: 1100px) {
  .tab-title {
    min-height: 54px;
    margin-top: 10px;
  }

  .prod-description {
    min-height: 240px !important;
  }

  .adminaccount-info-croper .img-logo-upload {
    width: 80%;
  }

  .text-xl,
  .tble-th-txt,
  .tble-td-txt {
    font-size: 12px !important;
  }

  .custom-checkbox-select .css-e56m7-control,
  .custom-checkbox-select .css-1rjh3ff-control,
  .custom-time-picker {
    min-height: 45px;
    padding: 0;
  }

  .date-pickers,
  .custom-time-picker {
    height: 45px;
    font-size: 12px !important;
  }

  .btn-lighblue,
  .btn-lighblue-border,
  .btn-lighblue,
  .btn-lighblue-border,
  .btn-gray,
  .tox .tox-button--secondary {
    height: 45px;
  }

  #cancel-booking-modal {
    width: 60%;
  }
}

@media only screen and (max-width: 1024px) {
  .header-area-height-set {
    height: 60px;
  }

  .payment-view-approve-modal #paymentviewapprove {
    width: 85%;
  }

  .prasantage-input::after,
  .dollor-sign::after {
    font-size: 16px;
  }

  .date-pickers:after {
    right: 46px;
  }

  .select-all-member-popup #select-All-Members {
    width: 100%;
    margin: 20px auto;
  }

  .custom-tbl-td td {
    padding: 12px 10px;
  }

  .voucher-sec .date-pickers {
    width: auto;
  }

  .input-sec-texts:nth-child(odd) {
    padding-right: 0;
  }

  .input-sec-texts:nth-child(even) {
    padding-left: 0;
  }

  #cancel-booking-modal {
    width: 70%;
  }

  .main-header-section {
    padding: 0;
  }

  .authority-limit-new-model #add-edit-modal {
    width: 80%;
  }

  .company-logo-cropper .img-logo-upload img {
    width: 80%;
  }

  #add-edit-modal {
    width: 100%;
  }

  .delete-text-line-after::after {
    left: 57%;
  }

  .delete-text-line-before::before {
    left: 41%;
  }

  .email-template-manage-sec table tbody .custom-tbl-td {
    display: block;
  }
}

.btn-lightblue {
  background: var(--bgColor);
  border: none;
  /* Remove borders */
  color: white;
  /* White text */
  padding: 12px 16px;
  /* Some padding */
  font-size: 16px;
  /* Set a font size */
}

.white-background .tiny-editor-sec .tox-tinymce .tox-editor-container .tox-sidebar-wrap .tox-edit-area .tox-edit-area__iframe {
  background-color: white !important;
}

.tox-edit-area__iframe {
  background-color: #809eae !important;
}

.orgprofile-codes {
  min-height: 140px;
}


.tiny-font-default {
  font-family: 'Roboto' !important;
  font-size: 16px !important;
}

.tiny-font-default div {
  border: none !important;
}


.tiny-font-default .tiny-editor-sec {
  border: 1px solid #757575 !important;
}

.tiny-font-default p {
  font-family: 'Roboto' !important;
  font-size: 16px !important;
  border: none !important;
}

.tiny-font-default span {
  font-family: 'Roboto' !important;
  font-size: 16px !important;
  border: none !important;
}
.orgprofile-codes div{
  border: none !important;
}